import PropTypes from 'prop-types';
import React from 'react';

import ContentPlaceholder from '../../../../client/reusable_components/ContentPlaceholder';

import { buildLocation } from '../../../../services/country_data';
import { isPendingOrVerified } from '../../../../utility/user_helpers';
import { getTierCopy } from '../../../../client/reusable_components/Badge';

import typography from '../../../../styles/global_ui/typography.css';
import styles from './profile.css';

const UserInfo = ({ isProfileOwner, profile }) => {
  const shouldShow = {
    bio: profile.bio && profile.bio.length > 0,
    joined: profile.created_month_year !== null,
    location: profile.country_iso2 && profile.country_iso2.length > 0,
    skills: profile.skills && profile.skills.length > 0,
  };

  if ((!shouldShow['bio'] && !shouldShow['skills'] && !shouldShow['location'] & !shouldShow['joined']) && !isProfileOwner) return null;

  const sectionContent = {
    bio: (bio) => (
      <p>{bio}</p>
    ),
    joined: (created_month_year) => (
      <p>{created_month_year}</p>
    ),
    skills: (skills) => (
      <p>
        {skills.map((skill, i, list) => (
          <span key={i}>
            {skill}
            {i === list.length - 1 ? '' : ', '}
          </span>
        ))}
      </p>
    ),
    location: (city, state, country_iso2) => (
      <p>{buildLocation(city, state, country_iso2)}</p>
    ),
  };

  const sections = {
    bio: (placeholder) => {
      const content = placeholder || sectionContent['bio'](profile.bio);

      return getSection('bio-info', 'Bio', styles.panelPositionLarge, content);
    },
    joined: (placeholder) => {
      const content = placeholder || sectionContent['joined'](profile.created_month_year);

      return getSection('joined-info', 'Joined', styles.panelPositionSmall, content);
    },
    skills: (placeholder) => {
      const content = placeholder || sectionContent['skills'](profile.skills);

      return getSection('skills-info', 'Skills', styles.panelPositionLarge, content);
    },
    location: (placeholder) => {
      const content = placeholder || sectionContent['location'](profile.city, profile.state, profile.country_iso2);

      return getSection('location-info', 'Location', styles.panelPositionSmall, content);
    },
  };

  function composePanelA() {
    return isProfileOwner
      ? [
          (shouldShow['bio'] ? sections['bio']() : getPlaceholder('bio')),
          (shouldShow['joined'] ? sections['joined']() : null),
        ]
      : [
          (shouldShow['bio'] && sections['bio']()) || (shouldShow['skills'] && sections['skills']()),
          (shouldShow['joined'] && sections['joined']()) || (shouldShow['location'] && sections['location']()),
        ];
  }

  function composePanelB() {
    return isProfileOwner
      ? [
          shouldShow['skills'] ? sections['skills']() : getPlaceholder('skills'),
          shouldShow['location'] ? sections['location']() : getPlaceholder('location'),
        ]
      : [
          (shouldShow['skills'] && shouldShow['bio'] ? sections['skills']() : null),
          (shouldShow['location'] && shouldShow['joined'] ? sections['location']() : null),
        ];
  }

  function getAbsentFields() {
    return [shouldShow['bio'], shouldShow['skills'], shouldShow['location']].filter((section) => !section).length;
  }

  function getPlaceholder(section) {
    return sections[section](<ContentPlaceholder isProfileOwner={isProfileOwner} type={`no-${section}`} />);
  }

  function getSection(key, title, panelStyles, content) {
    return (
      <div key={key} className={panelStyles}>
        <span className={`${typography.bodyM} ${typography.bold}`}>{title}</span>
        <div className={`${styles.userInfoContent} ${typography.bodyM}`}>{content}</div>
      </div>
    );
  }

  return (
    <div className={styles.userInfo}>
      <div className={styles.userInfoPanelA}>{composePanelA()}</div>
      {!isProfileOwner && getAbsentFields() > 1
        ? null
        : (
          <div className={styles.userInfoPanelB}>{composePanelB()}</div>
          )}
      {isProfileOwner && (
        <div className={styles.userInfoPanelB}>
          <div className={styles.panelPositionLarge} />
          <div className={styles.panelPositionSmall}>
            <span className={`${typography.bodyM} ${typography.bold}`}>
              Verification status
            </span>
            <p>
              {getTierCopy(profile.tier)}
              {' '}
              {!isPendingOrVerified(profile.tier) && (
                <a
                  href="/verify"
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  Get verified now!
                </a>
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  isProfileOwner: PropTypes.bool,
  profile: PropTypes.shape({
    avatar: PropTypes.shape({
      file: PropTypes.shape({ url: PropTypes.string }),
      id: PropTypes.number,
    }),
    bio: PropTypes.string,
    city: PropTypes.string,
    country_iso2: PropTypes.string,
    created_month_year: PropTypes.string,
    email: PropTypes.string,
    hourly_rate: PropTypes.number,
    id: PropTypes.number,
    interest: PropTypes.array,
    name: PropTypes.string,
    skills: PropTypes.array,
    state: PropTypes.string,
    stats: PropTypes.shape({
      followers: PropTypes.number,
      following: PropTypes.number,
      projects: PropTypes.number,
      reputation: PropTypes.number,
    }),
    user_name: PropTypes.string,
    website: PropTypes.string,
  }).isRequired,
};

UserInfo.defaultProps = {};

export default UserInfo;
