import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import typography from '../../../../styles/global_ui/typography.css';
import styles from './side_panel.css';

const SEGMENT_HEIGHT = 74; // The distance b/w 2 events on the time indicator line

class Timeline extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { shouldShowTimeIndicatorLine: false };
  }

  componentDidMount() {
    this.setState({ shouldShowTimeIndicatorLine: true });
  }

  /**
   * HELPERS
   */
  _eventRowStyle(isPast) {
    return isPast ? styles.pastEvent : styles.event;
  }

  _getBaseLineHeight(eventsCount) {
    const segmentsCount = eventsCount - 1;

    return `${segmentsCount * SEGMENT_HEIGHT}px`;
  }

  _getProgressLineHeight(challengeEvents, nowMs) {
    const eventsCount = challengeEvents.length;
    const firstEvent = challengeEvents[0];
    const lastEvent = challengeEvents[eventsCount - 1];

    if (eventsCount === 1 || !firstEvent.isPast) return '0px';

    return lastEvent.isPast
      ? this._getBaseLineHeight(eventsCount)
      : (this._getProgressSegmentsCount(challengeEvents, nowMs) * SEGMENT_HEIGHT) + 'px';
  }

  _getProgressSegmentsCount(challengeEvents, nowMs) {
    return challengeEvents.reduce((acc, event, i) => {
      const nextEvent = challengeEvents[i + 1];

      if (!nextEvent) return acc;
      if (nextEvent.isPast) return acc + 1;
      if (event.isPast && !nextEvent.isPast) {
        const progressRatio = (nowMs - event.ms) / (nextEvent.ms - event.ms);

        return acc + progressRatio;
      }

      return acc;
    }, 0);
  }

  /**
   * VIEWS
   */
  _getEventRow({ date, headerText, isPast, name }) {
    return (
      <div key={name} className={this._eventRowStyle(isPast)}>
        <p className={`${typography.bodyL} ${typography.bold}`}>{headerText}</p>
        <p className={typography.bodyS}>{date}</p>
      </div>
    );
  }

  _getEventsView({ challengeEvents }) {
    return (
      <div className={styles.eventsContainer}>
        {challengeEvents.map((event) => this._getEventRow(event))}
      </div>
    );
  }

  _getTimeIndicatorLine({ challengeEvents, nowMs }) {
    const eventsCount = challengeEvents.length;
    const baseLineHeight = this._getBaseLineHeight(eventsCount);
    const progressLineHeight = this._getProgressLineHeight(challengeEvents, nowMs);

    return (
      <div className={styles.baseLine} style={{ height: baseLineHeight }}>
        <div className={styles.progressLine} style={{ height: progressLineHeight }} />
      </div>
    );
  }

  render() {
    const hasPreRegDate = this.props.contestStatus === 'PRE_REGISTRATION';

    return (
      <div>
        <h5 className={`${styles.rowHeader} ${typography.h5}`}>
          {hasPreRegDate && (
            <span className={`${typography.error}`}>
              Expected*
              {' '}
            </span>
          )}
          Timeline
        </h5>
        <div className={styles.timelineContainer}>
          {this.state.shouldShowTimeIndicatorLine
          && this._getTimeIndicatorLine(this.props)}
          {this._getEventsView(this.props)}
        </div>
      </div>
    );
  }
}

Timeline.propTypes = {
  challengeEvents: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired,
    isPast: PropTypes.bool.isRequired,
    ms: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  contestStatus: PropTypes.string.isRequired,
  nowMs: PropTypes.number.isRequired,
};

export default Timeline;
